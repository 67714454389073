.slider-nav {
  position: relative;
  display: inline-block;
  padding: 0 23px;

  .slick-dots {
    position: static;
    width: auto;
    display: flex !important;
    align-items: center;

    li,
    button,
    button::before {
      width: auto;
      height: auto;
    }

    li {
      margin: 0 8px;
    }

    button::before {
      content: '\f2f8';
      font-family: 'Font Awesome 6 Pro', sans-serif;
      font-size: 30px;
      font-weight: 700;
      position: relative;
      opacity: 1;
      color: $black;
    }

    .slick-active button::before {
      content: '\f111';
      color: $brand-primary;
      opacity: 1;
    }
  }

  .slick-arrow {
    height: auto;
    width: auto;

    &::before {
      font-family: 'Font Awesome 6 Pro', sans-serif;
      font-weight: 700;
      font-size: 30px;
      color: $black;
      opacity: 1;
    }

    &.slick-prev {
      left: -15px;

      &::before {
        content: '\f0a8';
      }
    }

    &.slick-next {
      right: -15px;

      &::before {
        content: '\f0a9';
      }
    }
  }
}

.testimonials-panel {
  @include tablet {
    padding-top: 43px;
  }

  > .container {
    @include hd {
      max-width: 1625px;
    }
  }

  .testimonials-carousel {
    margin-top: 30px;
    margin-bottom: 15px;

    @include tablet {
      margin-top: 60px;
    }
  }

  .testimonial {
    padding: 0 15px;

    p {
      font-size: 15px;
    }
  }
}

footer.footer-panel {
  background-color: $white;
  padding: 20px 0 30px;

  @include tablet {
    padding-top: 30px;
  }

  @include wide {
    padding-top: 50px;
  }

  * {
    font-size: 15px;
    line-height: 2;
  }

  a,
  i {
    color: $body-color;

    @include links-transition();

    &:hover {
      color: lighten($brand-primary, 10%);
    }
  }

  i {
    font-size: 22px;

    @include tablet {
      font-size: 15px;
    }
  }

  [href^="tel:"] {
    color: $body-color !important;
  }

  .logos {
    width: 100%;
    text-align: center;

    @include wide {
      max-width: 340px;
      margin-top: -37px;
    }
  }

  .nav-full {
    li {
      margin: 0 20px;
    }

    a {
      line-height: 2.7;

      @include tablet {
        line-height: 2;
      }
    }
  }

  .associations {
    width: 100%;
  }
}

footer.copyright {
  background-color: #e6e6e6;
  padding: 9px 0 5px;

  *,
  a:hover {
    font-size: 11px;
    color: $body-color;
    font-weight: 500;
  }
}
