.btn {
  border-radius: 0;
  border: 0;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;
  padding: 15px 15px 11px;

  @include links-transition();

  @include tablet {
    width: auto;
    padding: 17px 29px 9px;
  }

  @include wide {
    font-size: 17px;
  }
}

.btn-primary {
  color: $white;
}

.search-submit {
  background-color: $brand-primary;
  color: $white !important;
  width: auto;

  @extend .btn;
}
