a[href^="tel:"] {
  color: $body-color;
}

a[href$=".pdf"]::after {
  content: '\f1c1';
  margin-left: 7px;
}

.google-map {
  position: relative;
  overflow: hidden;
  flex-grow: 1;

  iframe {
    width: 100%;
    height: 100%;
    min-height: 400px;
    border: 0;
    position: relative;

    @include laptop {
      min-height: 300px;
    }
  }
}

.search-form {
  display: flex;
  flex-wrap: wrap;

  label {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

.pagination-style {
  a,
  span {
    margin-right: 10px;
    text-decoration: none !important;
  }

  .nav-links {
    padding: 5px 10px 5px 0;
    border-radius: 0;
  }
}

.page-content-panel {
  background-color: $white;

  .the-content {
    > h2:not(:first-child) {
      margin-top: 35px;
    }
  }
}

.page-header h1 {
  text-align: center;
  margin-bottom: 25px;

  @include tablet {
    margin-bottom: 35px;
  }
}

.form-iframe {
  iframe {
    height: 100vh !important;
  }
}
