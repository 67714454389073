#skip-navigation {
  position: absolute;
  display: block;
  background: $white;
  color: $brand-primary;
  padding: 15px;
  text-align: center;
  transform: translateY(-101%);
  transition: transform 0.25s ease-in-out;
  z-index: 10;

  &:focus {
    transform: translateY(0);
    transition: transform 0.25s ease-in-out;
  }
}

#modal {
  overflow: scroll;
  background-color: rgba($black, 0.5);

  .modal-title {
    color: $brand-primary;
    font-family: $secondary-font;
  }

  .modal-dialog {
    @include tablet {
      max-width: 75%;
    }

    @include hd {
      max-width: 50%;
    }
  }
}

.brand {
  max-width: 230px;

  @include tablet {
    max-width: 275px;
  }

  @include wide {
    max-width: 328px;
  }
}

.banner {
  background-color: $black;
  position: relative;
  z-index: 2;
  border-bottom: 1px solid rgba($black, 0.1);

  @include tablet {
    padding-bottom: 5px;
    border-bottom: 0;
  }

  .nav-top {
    padding: 11px 0 7px;

    @include tablet {
      padding: 19px 0 10px;
    }

    @include desktop {
      padding: 23px 0 14px;
    }

    > li:not(:last-of-type) {
      margin-right: 30px;

      @include wide {
        margin-right: 46px;
      }

      @include hd {
        margin-right: 64px;
      }
    }

    a {
      color: $white;
      font-weight: 600;
      letter-spacing: 1px;
    }

    a:hover {
      color: lighten($brand-primary, 10%);
    }

    a[href^="tel:"] {
      color: $white;
    }
  }

  .nav-primary {
    position: relative;
    z-index: 0;

    @include laptop {
      margin-right: -12px;
    }

    &::before {
      @include tablet {
        content: '';
        background: linear-gradient(90deg, $brand-primary 10px, transparent 10px 20px, $brand-primary 0%);
        position: absolute;
        top: 0;
        right: -1000px;
        bottom: 0;
        left: -40px;
        z-index: -1;
        transform: skewX(-45deg);
      }

      @include wide {
        left: -76px;
      }
    }

    > li:not(:last-of-type) {
      @include wide {
        margin-right: 26px;
      }

      @include hd {
        margin-right: 34px;
      }
    }

    a {
      color: $white;
      font-weight: 500;
      text-transform: uppercase;
      padding: 19px 12px 10px;
      position: relative;

      @include links-transition();

      @include hd {
        letter-spacing: 0.7px;
      }
    }

    a:hover {
      background-color: darken($brand-primary, 7%);
    }
  }
}

li.menu-item-has-children.open .sub-menu {
  visibility: visible;
  opacity: 1;
}

.sub-menu {
  visibility: hidden;
  opacity: 0;
  list-style: none;
  padding: 0;
  z-index: 1;

  @include links-transition((visibility, opacity, top));

  li {
    @include links-transition();

    a {
      padding: 15px 20px 10px !important;
    }
  }
}

.banner .sub-menu {
  box-shadow: 0 3px 5px rgba($black, 0.44);
  position: absolute;
  max-width: 320px;
  min-width: 180px;
}

.nav-primary .sub-menu {
  background-color: $brand-primary;

  li {
    border-top: 1px solid darken($brand-primary, 7%);
  }
}

.nav-top .sub-menu {
  background-color: $black;

  li {
    border-top: 1px solid rgba($white, 0.2);
  }
}

.banner,
.sideNav {
  li.menu-item-has-children > a {
    @extend .down-caret;

    &::after {
      margin-left: 9px;
      position: relative;
    }
  }

  li.menu-item-has-children.open > a {
    @extend .up-caret;
  }
}
