.icon {
  flex-shrink: 0;
  position: relative;
  background-color: $brand-primary;
  border: 3px solid $white;
  box-shadow: 0 0 0 2px $brand-primary;
  border-radius: 50%;
  height: 103px;
  width: 103px;
  margin: 3px auto 30px;

  @include laptop {
    margin-right: 26px;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.underline {
  border-bottom: 2px solid #4a4b47;
  padding-bottom: 7px;
  margin-bottom: 12px;

  @include hd {
    width: 93%;
  }
}

#why-choose {
  @include tablet {
    padding-top: 43px;
  }

  .header h2 {
    margin-bottom: 14px;
  }

  .row {
    margin-top: 58px;
  }

  .feature-col {
    margin-bottom: 35px;

    @include wide {
      margin-bottom: 43px;
    }
  }

  .feature {
    max-width: 450px;
    margin-right: auto;
    margin-left: auto;

    p {
      margin-bottom: 0;
      font-size: 15px;
    }
  }

  .feature-col:nth-of-type(3n+1) .feature {
    @include wide {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .feature-col:nth-of-type(3n+2) .feature {
    @include wide {
      margin-right: auto;
      margin-left: auto;
    }
  }

  .feature-col:nth-of-type(3n+3) .feature {
    @include wide {
      margin-right: 0;
      margin-left: auto;
    }
  }
}

.service-panels {
  .service-row {
    padding: 30px 0;
    position: relative;

    @include tablet {
      padding: 40px 0;
    }

    @include laptop {
      padding: 70px 0;
      margin-bottom: 10px;
    }
  }

  .image {
    background-size: cover;
    min-height: 250px;
    margin-bottom: 1px;

    @include tablet {
      min-height: 400px;
    }

    @include laptop {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 50%;
      margin-bottom: 0;
    }
  }

  .content {
    padding: 30px 25px;
    position: relative;
    text-align: center;

    @include tablet {
      padding: 40px 35px;
    }

    @include laptop {
      width: 57.5%;
      padding: 65px 8.5% 60px;
      text-align: left;
    }

    @include wide {
      padding: 98px 8.5% 80px;
    }

    > * {
      position: relative;
      z-index: 1;
    }

    .h1 {
      margin-bottom: 22px;
    }

    p {
      line-height: 1.5;
    }

    .btn {
      margin-top: 10px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 10px;
      right: 10px;
      bottom: 10px;
      left: 10px;
    }
  }

  .service-row:nth-of-type(odd) {
    background-color: $black;

    .image {
      right: 0;
    }

    .content {
      background-color: #f9f5f5;

      &::before {
        border: 1px solid $black;
      }
    }
  }

  .service-row:nth-of-type(even) {
    background-color: #e8e8e8;

    .image {
      left: 0;
    }

    .content {
      background-color: $black;
      margin-left: auto;

      &::before {
        border: 1px solid #f9f5f5;
      }

      .h1,
      p {
        color: $white;
      }
    }
  }
}
