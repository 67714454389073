.hero-panel,
.prefooter-panel {
  position: relative;
  background-color: #e8e8e8;

  .container {
    position: relative;
    z-index: 1;
  }
}

body.home .hero-panel {
  @include laptop {
    height: calc(100vh - 122px);
    min-height: 590px;
    max-height: 820px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @include wide {
    min-height: 620px;
  }
}

.hero,
.prefooter {
  background-size: cover;
  height: 250px;

  @include tablet {
    height: 400px;
  }

  @include laptop {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.prefooter-panel .hero-content {
  @include wide {
    margin: 150px 0;
  }
}

.hero-content {
  position: relative;
  z-index: 0;
  text-align: center;
  padding: 20px 0 30px;

  @include tablet {
    padding: 30px 0 40px;
  }

  @include laptop {
    border: 1px solid rgba($white, 0.9);
    padding: 39px 54px 39px 48px;
    margin: 80px 0 90px;
    margin-left: -11px !important;
    display: inline-block;
    text-align: left;
  }

  &::before {
    @include laptop {
      content: '';
      background-color: rgba($white, 0.9);
      position: absolute;
      z-index: -1;
      top: 10px;
      right: 10px;
      bottom: 10px;
      left: 10px;
    }
  }

  .line1,
  .line2 {
    font-size: 40px;
    line-height: 1.1;

    @include laptop {
      line-height: 0.9;
    }
  }

  .line1 {
    display: inline;

    @include laptop {
      font-size: 55px;
      display: block;
    }

    @include wide {
      font-size: 69px;
    }
  }

  .line2 {
    margin-bottom: 7px;
    display: inline;

    @include laptop {
      font-size: 65px;
      display: block;
    }

    @include wide {
      font-size: 86px;
    }
  }

  .line3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;

    @include laptop {
      margin-left: 3px;
    }

    @include wide {
      font-size: 18px;
      letter-spacing: 0.15px;
    }
  }

  .cta-container {
    @include laptop {
      margin-right: -4px;
      margin-bottom: -54px;
    }
  }

  .btn {
    margin-top: 7px;

    &:not(:first-of-type) {
      @include tablet {
        margin-left: 20px;
      }
    }
  }
}

.overlay::after {
  @include laptop {
    content: '';
    background: linear-gradient(0.25turn, rgba($white, 0.7) 46%, rgba($white, 0) 63%);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.strapline {
  background-color: rgba(#1d1d1d, 1);
  text-align: center;
  position: relative;
  padding: 25px 0 20px;

  @include tablet {
    padding: 35px 0 30px;
  }

  @include laptop {
    background-color: rgba(#1d1d1d, 0.9);
  }

  @include wide {
    padding: 57px 0 49px;
  }

  p {
    color: #e0e0e0;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0.75px;
    line-height: 1.7;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
